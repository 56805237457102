<template>
    <div class="quickfinder">
        <h3 class=" quickfinder__header header">Quickfinder</h3>
        <div class="quickfinder__filterrow">
            <Multiselect v-model="category" 
            :options="eventStore.categories" 
            track-by="id" 
            label="label" 
            :show-labels="false" 
            :placeholder="'Themenwelt'"
            aria-label="Themenwelt auswählen"
            :aria-expanded="categoryIsOpen"
            @open="categoryIsOpen = true"
            @close="categoryIsOpen=false"
            >
                <template #noResult>Keine Ergebnisse...</template>
                <template #noOptions>Keine Optionen verfügbar...</template>
            </Multiselect>
            <Multiselect v-model="place" 
            :options="eventStore.getPlaces" 
            :show-labels="false" 
            :placeholder="'Veranstaltungsort'"
            aria-label="Veranstaltungsort auswählen"
            :aria-expanded="placeIsOpen"
            @open="placeIsOpen = true"
            @close="placeIsOpen=false"
            >
                <template #noResult>Keine Ergebnisse...</template>
                <template #noOptions>Keine Optionen verfügbar...</template>
            </Multiselect>
            <Multiselect v-model="timespan" 
            :options="dateOptions"
            :show-labels="false"
            :placeholder="'Zeitraum'"
            :searchable="false"
            @update:modelValue="handleDateSelection"
            aria-label="Zeitraum auswählen"
            :aria-expanded="dateIsOpen"
            @open="dateIsOpen = true"
            @close="dateIsOpen=false"
            >
                <template #noResult>Keine Ergebnisse...</template>
                <template #noOptions>Keine Optionen verfügbar...</template>
            </Multiselect>
            <button class="btn btn-primary" @click="navigateWithParams">Treffer anzeigen</button>
        </div>
        <div class="quickfinder__commonrow">
            <h3>Beliebte Angebote</h3>
            <div class="quickfinder__commonrow__buttons">
                <a href="/online-seminare" class="offer">Online-Seminare</a>
                <a href="/bildungsurlaub" class="offer">Bildungsurlaub</a>
                <a href="" @click.prevent="navigateFreeEvents" class="offer">Kostenlos</a>
            </div>
        </div>
    </div>    
</template>

<script setup>
import Multiselect from 'vue-multiselect';
import("vue-multiselect/dist/vue-multiselect.min.css");
import { ref, computed, onMounted } from 'vue';

const props = defineProps({
    externalUrl: {
        type: String
    }
})

const categoryIsOpen = ref(false);
const placeIsOpen = ref(false);
const dateIsOpen = ref(false);

/*
*  Event-Store initialisieren
*/

import { createApp } from 'vue';
import { createPinia, defineStore } from 'pinia';

const useEventStore = defineStore('event', () => {
    const loading = ref(false);

    const events = ref([]);
    const categories = ref([]);
    const urls = ref([]);
    
    const getPlaces = computed(() => {
        return [...new Set(events.value.map(event => event.place))].sort();
    });

    const loadEvents = async () => {
        loading.value = true;

        const response = await fetch("/api/events.json");
        const loadedEvents = await response.json();        
        events.value = [...loadedEvents];

        // Categories befüllen
        const duplicateCategories = ref([]);
        loadedEvents.forEach(event => {
            Object.entries(event.categories).map(([key, value]) => duplicateCategories.value.push(({id: key, label: value})))
        })

        categories.value = duplicateCategories.value
        .filter((value, index, self) =>
            index === self.findIndex((obj) => 
                Object.keys(obj)[0] === Object.keys(value)[0] && 
                Object.values(obj)[0] === Object.values(value)[0]
            )
        )
        .sort((a, b) => {
            const getNumericValue = (id) => {
                // Seperators entfernen und erste 4 digits vergleichen. (Typen haben ID 999-1, 999-2, 999-3-1 etc. im Vergleich zu normalen Kategorien ID 1, 2, 3 etc.)
                return Number.parseInt(id.replace(/-/g, '').slice(0, 4));
            };        
            
            const valueA = getNumericValue(a.id);
            const valueB = getNumericValue(b.id);
            
            return valueA - valueB;
        });   
        
        loading.value = false;
    };

    const loadUrls = async () => {
        try {
            const response = await fetch('/api/events/category-urls.json');

            const data = await response.json();

            if(response.ok) {
                urls.value = [...data];
            } else {
                throw new Error(`Request failed: ${response.statusText}`)
            }
        } catch (err) {
            console.error(err);
            
        }
    }

    return {
        events,
        categories,
        urls,
        getPlaces,
        loadEvents,
        loadUrls
    };
});

const app = createApp({});
const pinia = createPinia();
app.use(pinia);


const eventStore = useEventStore();

const category = ref(null);
const place = ref("");
const timespan = ref("");
const date = ref([]);

let url = new URL(window.location.origin + props.externalUrl);

const dateOptions = ref([
    "Heute",
    "nächste Woche",
    "nächsten Monat",
    "nächstes Quartal",
    "nächstes Halbjahr",
    "nächstes Jahr"
])

/*
*   Datum verarbeiten
*/

const todayStart = new Date();
const todayEnd = new Date();
todayStart.setHours(0,0,0,0);
todayEnd.setHours(23,59,59,999);

function handleDateSelection(event){
    event === "Heute" ? setDateToday() :
    event === "nächste Woche" ? setDateNextWeek() :
    event === "nächsten Monat" ? setDateNextMonth() :
    event === "nächstes Quartal" ? setDateNextQuarter() :
    event === "nächstes Halbjahr" ? setDateNextHalf() :
    setDateNextYear();
}

function setDateToday() {
    date.value = [todayStart, todayEnd];
}

function setDateNextWeek() {
    const dayOfWeek = todayStart.getDay();
    const daysUntilMonday = (8 - dayOfWeek) % 7;

    const nextMonday = new Date(todayStart);
    nextMonday.setDate(todayStart.getDate() + daysUntilMonday);

    const followingSunday = new Date(nextMonday);
    followingSunday.setDate(nextMonday.getDate() + 6);
    followingSunday.setHours(23,59,59,999);

    date.value = [nextMonday, followingSunday];
}

function setDateNextMonth() {
  const currentMonth = todayStart.getMonth();
  const currentYear = todayStart.getFullYear();

  const nextMonthFirstDay = new Date(currentYear, currentMonth + 1, 1);
  nextMonthFirstDay.setHours(0, 0, 0, 0);

  const nextMonthLastDay = new Date(currentYear, currentMonth + 2, 0);
  nextMonthLastDay.setHours(23, 59, 59, 999);

  date.value = [nextMonthFirstDay, nextMonthLastDay];
}

function setDateNextQuarter() {
    const currentMonth = todayStart.getMonth();
    const currentYear = todayStart.getFullYear();
    
    // Berechne den Start des nächsten Quartals
    const currentQuarter = Math.floor(currentMonth / 3);
    const nextQuarterStartMonth = (currentQuarter + 1) * 3;
    const nextQuarterYear = currentYear + Math.floor(nextQuarterStartMonth / 12);
    const normalizedStartMonth = nextQuarterStartMonth % 12;
    
    const quarterStart = new Date(nextQuarterYear, normalizedStartMonth, 1);
    quarterStart.setHours(0, 0, 0, 0);
    
    // Berechne das Ende des nächsten Quartals
    const quarterEnd = new Date(nextQuarterYear, normalizedStartMonth + 3, 0);
    quarterEnd.setHours(23, 59, 59, 999);
    
    date.value = [quarterStart, quarterEnd];
}

function setDateNextHalf() {
    const currentMonth = todayStart.getMonth();
    const currentYear = todayStart.getFullYear();
    
    // Bestimme ob wir im ersten oder zweiten Halbjahr sind
    const currentHalf = Math.floor(currentMonth / 6);
    const nextHalfStartMonth = (currentHalf + 1) * 6;
    const nextHalfYear = currentYear + Math.floor(nextHalfStartMonth / 12);
    const normalizedStartMonth = nextHalfStartMonth % 12;
    
    const halfStart = new Date(nextHalfYear, normalizedStartMonth, 1);
    halfStart.setHours(0, 0, 0, 0);
    
    // Setze das Ende auf den letzten Tag des 6. Monats
    const halfEnd = new Date(nextHalfYear, normalizedStartMonth + 6, 0);
    halfEnd.setHours(23, 59, 59, 999);
    
    date.value = [halfStart, halfEnd];
}

function setDateNextYear() {
    const nextYear = todayStart.getFullYear() + 1;
    
    // Setze Start auf 1. Januar des nächsten Jahres
    const yearStart = new Date(nextYear, 0, 1);
    yearStart.setHours(0, 0, 0, 0);
    
    // Setze Ende auf 31. Dezember des nächsten Jahres
    const yearEnd = new Date(nextYear, 11, 31);
    yearEnd.setHours(23, 59, 59, 999);
    
    date.value = [yearStart, yearEnd];
}

/*
*   URL Params anwenden
*/

function handleUrlParams() {
    if(category.value && category.value.id){
        const categoryObj = eventStore.urls.find(obj => obj[category.value.id] !== undefined);
        if(categoryObj){
            const categoryUrl = categoryObj[category.value.id];
            
            url = new URL(categoryUrl);            
        }
    }

    const params = new URLSearchParams(url.search);
    params.set("eventFilter", 'BKh4AGK8A8ttX8mqv6cfew2dH');
    // if(category.value && category.value.id) {
    //     params.set("category", category.value?.id);        
    // }
    if(place.value) {
        params.set("place", place.value);
    }
    if(date.value) {
        params.set("date", date.value);
    }

    url.search = params.toString();
}

function navigateWithParams() {
    handleUrlParams();
    window.location.href = url;
}

function navigateFreeEvents() {
    const priceUrl = new URL(window.location.origin + '/alle-bildungsangebote');
    const priceParams = new URLSearchParams(priceUrl.search);

    priceParams.set("eventFilter", 'BKh4AGK8A8ttX8mqv6cfew2dH');
    priceParams.set("price", [0,0]);

    priceUrl.search = priceParams.toString();

    window.location.href = priceUrl;
}

onMounted(async () => {
  await eventStore.loadEvents();
  await eventStore.loadUrls();  
});

</script>

<style src="node_modules/vue-multiselect/dist/vue-multiselect.css"></style>
