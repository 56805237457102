import { createApp } from "vue";

import QuickFinder from '../views/QuickFinder.ce.vue'
import { createPinia } from "pinia";

customElements.define('quickfinder-component', class extends HTMLElement {
    connectedCallback() {
        const container = document.createElement('div');
        container.id = 'app';
        this.appendChild(container);

        const externalUrl = this.getAttribute('data-url');

        const app = createApp(QuickFinder, {externalUrl});
        app.use(createPinia());
        app.mount(container);
    }
});
